@import "../custom";

.page {
    .img {
        background-image: url(../img/page-bg.jpg);
        background-size: 100%;
        height: 200px;
        opacity: $bg-image-opacity;
    }

    .top-spacer {
        height: 5em;
    }

    .bottom-spacer {
        height: 56px;
    }
}

.list-card {
    margin: 1em 0;
    width: 24em;

    .card-body:nth-of-type(1) {
        padding: 0;
        flex-grow: 0;

        .card-title {
            background-color: $secondaryColor;
            color: $white;
            padding: 0.25em;
            margin-bottom: 0;
        }

        .service-image {
            height: 100px;
            width: 100%;
            overflow: hidden;

            img {
                opacity: $bg-image-opacity;
            }
        }
    }

    .card-body:nth-of-type(2) {
        font-size: 1.1em;
    }

    ul {
        padding-left: $list-group-item-padding-x / 2;
        list-style: none;

        li::before {
            content: "\2022";

            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }

        li.text::before {
            color: $primaryColor;
        }

        li {
            & span.item-title {
                font-weight: bold;
            }

            & span.item-info {
                font-size: 0.8em;
            }
        }
    }
}
